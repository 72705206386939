// Variables and Functions (Custom and Bootstrap)

@import "src/styles/foundation";

// Bootstrap Reboot

@import "~bootstrap-scss/reboot";

// Custom Styles

html,
body,
#root {
    height: 100%;
}
