@import "src/styles/foundation";

.container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;

  h1 {
    color: $white;
    font-size: map-get($font-size-map, 7);
    font-weight: normal;
    letter-spacing: 0.3rem;
    text-align: center;
    text-transform: uppercase;
  }

  p {
    color: $white;
    font-size: map-get($font-size-map, 2);
    letter-spacing: 0.3rem;
    text-align: center;
    text-transform: uppercase;
  }

  .separator {
    margin: auto;
    width: 2rem;
    z-index: 101;

    svg {
      path {
        stroke: $t-white-a-24;
        stroke-width: 0.125rem;
      }
    }
  }

  .social {
    display: flex;
    justify-content: space-between;
    z-index: 105;

    .button {
      background-color: $t-black-a-02;
      border-color: $t-white-a-24;
      border-radius: 100%;
      border-style: solid;
      border-width: 0.125rem;
      color: $gray-10;
      margin: 2rem 1rem;
      transition: background-color 0.3s ease, border-color 0.3s ease, border-width 0.3s ease;
      width: 4rem;
      height: 4rem;

      svg {
        margin: 1.125rem;
        width: 1.5rem;
        height: 1.5rem;

        path {
          fill: $gray-10;
          transition: fill 0.3s ease;
        }
      }
    }

    .button:hover {
      background-color: $gray-10;
      border-color: $gray-10;
      color: $black;

      svg {
        path {
          fill: $black;
        }
      }
    }
  }

}