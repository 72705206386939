// Documents
// How you’ve been getting the Bootstrap grid all wrong—and how to fix it: https://medium.com/@erik_flowers/how-youve-been-getting-the-bootstrap-grid-all-wrong-and-how-to-fix-it-6d97b920aa40

// BOOTSTRAP VARIABLES
//
// Variables should follow the `$component-state-property-size` formula for consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

// https://refactoringui.com/previews/building-your-color-palette/
// https://design.gitlab.com/product-foundations/colors/
// https://www.w3schools.com/colors/colors_picker.asp
// https://github.com/mjswensen/color-steps
// https://contrast-ratio.com

// IMPORTANT: Adjust colors by adjusting the saturation (hue should remain the same and lightness increments/decrements should be similar)
// hsl(hue, saturation, lightness)

// $ npx color-steps "white" "rgb(0,54,77)" 8
// $ npx color-steps "rgb(0,54,77)" "black" 1

$white: #ffffff !default;
$gray-10: #fafafa !default;
$gray-50: #f0f0f0 !default;
$gray-100: #dbdbdb !default;
$gray-200: #bfbfbf !default;
$gray-300: #999999 !default;
$gray-400: #868686 !default;
$gray-500: #666666 !default;
$gray-600: #5e5e5e !default;
$gray-700: #525252 !default;
$gray-800: #404040 !default;
$gray-900: #303030 !default;
$gray-950: #1f1f1f !default;
$black: #000000 !default;

$t-white-a-02: rgba($white, 0.02) !default;
$t-white-a-04: rgba($white, 0.04) !default;
$t-white-a-06: rgba($white, 0.06) !default;
$t-white-a-08: rgba($white, 0.08) !default;
$t-white-a-24: rgba($white, 0.24) !default;

$t-black-a-02: rgba($black, 0.02) !default;
$t-black-a-04: rgba($black, 0.04) !default;
$t-black-a-06: rgba($black, 0.06) !default;
$t-black-a-08: rgba($black, 0.08) !default;
$t-black-a-24: rgba($black, 0.24) !default;

$primary: $white !default;
$secondary: $gray-500 !default;
//$success: $green !default;
//$info: $cyan !default;
//$warning: $yellow !default;
//$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-shadows: false !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these variables. Mostly focused on spacing.

$spacer-map: (
    0: 0,
    1: 0.25rem,
    2: 0.5rem,
    3: 0.75rem,
    4: 1rem,
    5: 1.5rem,
    6: 2rem,
    7: 3rem,
    8: 4rem,
    9: 6rem,
    10: 8rem,
    11: 12rem,
    12: 16rem,
    13: 24rem,
    14: 32rem,
    15: 40rem,
    16: 48rem,
    xs: 0.25rem,
    sm: 0.5rem,
    md: 0.75rem,
    lg: 1rem,
    xl: 1.5rem,
) !default;

$spacer: 1rem !default; // Assumes the browser default, typically `16px`: map-get($spacer-map, 4)

// Body
//
// Settings for the `<body>` element.

$body-bg: $black !default;
$body-color: $primary !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: "Source Sans Pro", sans-serif !default;
// stylelint-enable value-keyword-case

$font-size-map: (
    1: 0.75rem,
    2: 0.875rem,
    3: 1rem,
    4: 1.125rem,
    5: 1.25rem,
    6: 1.5rem,
    7: 1.875rem,
    8: 2.25rem,
    9: 3rem,
    10: 3.75rem,
    11: 4.5rem,
) !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
